// TrialFlowDialog.tsx
import React, { useState, useCallback, useEffect } from 'react';
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import { Typography, makeStyles, Theme, Button } from '@material-ui/core';
import { MODAL_STEPS, MODAL_TITLES, MODAL_MESSAGES, MODAL_HEADERS, MODAL_BUTTONS } from './TextMessagingConstants';
import { IVirtualNumber } from '@models/TextMessaging.models';
import { formatPhoneNumber } from '@helpers/functions/phoneNumberFormatter';
import TextMessagingService from "@services/TextMessaging.service";
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@helpers/hooks/useAppSelector.hook';
import { setAccountTrialStatus } from '@actions/userActions';
import UserService from '@services/User.service';

interface TrialFlowDialogProps {
  open: boolean;
  onClose: () => void;
  currentNumber: IVirtualNumber | null;
  onContinueToExistingFlow: () => void;
  activationHandler?: (direction: string) => Promise<void>;
  initialStep?: string;
  refreshVirtualNumbers?: () => void;
  hasTempNumber?: boolean;
}

const TrialFlowDialog: React.FC<TrialFlowDialogProps> = ({
  open,
  onClose,
  currentNumber,
  onContinueToExistingFlow,
  activationHandler,
  initialStep,
  refreshVirtualNumbers,
  hasTempNumber,
}) => {
  const [currentStep, setCurrentStep] = useState<string>(MODAL_STEPS.REGISTER_NUMBER);
  const classes = useStyles();
  const formattedNumber = currentNumber ? 
    formatPhoneNumber(currentNumber.phoneNumber.replace("+", "")) : "";
  const [tempNumber, setTempNumber] = useState<string | null>(null);
  const [endingTrial, setEndingTrial] = useState(false);
  const dispatch = useDispatch();
  const accountId = useAppSelector(state => state.authentication.accountId);
  const { EndTrial } = UserService();


  // Reset step when dialog opens
  useEffect(() => {
    if (open) {
	  setCurrentStep(initialStep ?? MODAL_STEPS.REGISTER_NUMBER);
	  setTempNumber(null);
    }
  }, [open]);

  const handleRegisterNumber = (confirmed: boolean) => {
    if (confirmed) {
      setCurrentStep(MODAL_STEPS.END_TRIAL);
    } else {
		onClose();
    }
  };

  const handleEndTrial = async (confirmed: boolean) => {
    if (!confirmed) {
		if (hasTempNumber) {
		  onClose();
		} else {
		  setCurrentStep(MODAL_STEPS.TEMP_NUMBER);
		}
		return;
	}
	setEndingTrial(true);
    try {
      if (accountId) {
        await EndTrial(accountId); 
        dispatch(setAccountTrialStatus(false)); 
      }
      const isIncomingOnly = currentNumber && !currentNumber.textMessagingAllowed;

      if (isIncomingOnly) {
        setCurrentStep(MODAL_STEPS.ACCOUNT_REGISTER);
      } else {
        onContinueToExistingFlow();
      }
    } catch (err) {
      console.error("Failed to end free trial: ", err);
      setCurrentStep(MODAL_STEPS.END_TRIAL_ERROR);
    } finally {
		setEndingTrial(false);
	}
  };

  const handleRegisterAccount = (confirmed: boolean) => {
	if (confirmed) {
		onContinueToExistingFlow();
	} else {
		handleActivateIncomingOnly();
		setCurrentStep(MODAL_STEPS.INCOMING_ONLY);
	}
  }

  const handleRegisterAccountForIncomingOnlyConfirmed = (confirmed: boolean) => {
	if (confirmed) {
		onContinueToExistingFlow();
	} else {
		onClose();
	}
  }

  const handleTempNumber = async (confirmed: boolean) => {
    if (!confirmed) {
      onClose();
      return;
    }
    try {
      const { RequestTempTollFreeNumber } = TextMessagingService();
      const dids = await RequestTempTollFreeNumber();
      if (dids.length > 0) {
        setTempNumber(dids[0].phoneNumber);
      }
	  if (refreshVirtualNumbers) {
		await refreshVirtualNumbers();
	  }
      setCurrentStep(MODAL_STEPS.TEMP_CONFIRM);
    } catch (error) {
      console.error("Error requesting temp toll-free number:", error);
      setCurrentStep(MODAL_STEPS.TEMP_ERROR);
    }
  };

  const handleActivateIncomingOnly = async () => {
		if (activationHandler) {
			try {
				await activationHandler('Inbound Only');
			} catch (error) {
				console.error("Error activating inbound only:", error);
			}
		}
  };

  const getCurrentContent = () => {
    const isTollFree = currentNumber?.tollFree;

    switch (currentStep) {
      case MODAL_STEPS.REGISTER_NUMBER:
        return {
		  title: isTollFree ? MODAL_TITLES.REGISTER_NUMBER.TOLLFREE : MODAL_TITLES.REGISTER_NUMBER.LOCAL,
          header: isTollFree ? `${MODAL_TITLES.REGISTER_NUMBER.TOLLFREE} ${formattedNumber}` : `${MODAL_TITLES.REGISTER_NUMBER.LOCAL_PROCEED} ${formattedNumber}`,
          message: MODAL_MESSAGES.REGISTER_NUMBER,
		  noButton: 'No',
		  yesButton: 'Yes',
          onConfirm: handleRegisterNumber,
        };

      case MODAL_STEPS.END_TRIAL:
        return {
          title: MODAL_TITLES.END_TRIAL,
		  header: MODAL_HEADERS.END_TRIAL,
          message: MODAL_MESSAGES.END_TRIAL,
		  noButton: MODAL_BUTTONS.END_TRIAL_NO,
		  yesButton: MODAL_BUTTONS.END_TRIAL_YES,
          onConfirm: handleEndTrial,
        };

	  case MODAL_STEPS.ACCOUNT_REGISTER:
        return {
          title: MODAL_TITLES.ACCOUNT_REGISTER,
		  header: MODAL_HEADERS.ACCOUNT_REGISTER,
          message: MODAL_MESSAGES.ACCOUNT_REGISTER,
		  noButton: MODAL_BUTTONS.ACCOUNT_REGISTER_NO,
		  yesButton: MODAL_BUTTONS.ACCOUNT_REGISTER_YES,
          onConfirm: handleRegisterAccount,
        };

	  case MODAL_STEPS.INCOMING_ONLY:
        return {
          title: MODAL_TITLES.INCOMING_ONLY,
		  header: MODAL_HEADERS.INCOMING_ONLY,
          message: MODAL_MESSAGES.INCOMING_ONLY,
		  noButton: 'Close',
          onConfirm: onClose,
        };

      case MODAL_STEPS.TEMP_NUMBER:
        return {
          title: MODAL_TITLES.TEMP_NUMBER.TOLLFREE,
		  header: MODAL_HEADERS.TEMP_NUMBER,
          message: MODAL_MESSAGES.TEMP_NUMBER,
		  noButton: MODAL_BUTTONS.TEMP_NUMBER_NO,
		  yesButton: MODAL_BUTTONS.TEMP_NUMBER_YES,
          onConfirm: handleTempNumber,
        };

	  case MODAL_STEPS.TEMP_CONFIRM:
        return {
          title: MODAL_TITLES.TEMP_CONFIRM,
		  header: MODAL_HEADERS.TEMP_CONFIRM,
          message: MODAL_MESSAGES.TEMP_CONFIRM,
		  noButton: 'Close',
          onConfirm: onClose,
        };

	  case MODAL_STEPS.TEMP_ERROR:
		return {
			title: MODAL_TITLES.TEMP_ERROR,
			header: MODAL_HEADERS.TEMP_ERROR,
			message: MODAL_MESSAGES.TEMP_ERROR,
			noButton: 'Close',
			onConfirm: onClose,
		};

	  case MODAL_STEPS.END_TRIAL_ERROR:
		return {
			title: MODAL_TITLES.END_TRIAL_ERROR,
			header: MODAL_HEADERS.END_TRIAL_ERROR,
			message: MODAL_MESSAGES.END_TRIAL_ERROR,
			noButton: 'Close',
			onConfirm: onClose, 
	  };

	  case MODAL_STEPS.OUTGOING_REGISTER:
	  	return {
			title: MODAL_TITLES.OUTGOING_REGISTER,
			header: MODAL_HEADERS.OUTGOING_REGISTER,
			message: MODAL_MESSAGES.OUTGOING_REGISTER,
			noButton: MODAL_BUTTONS.OUTGOING_REGISTER_NO,
			yesButton: MODAL_BUTTONS.OUTGOING_REGISTER_YES,
			onConfirm: handleRegisterAccountForIncomingOnlyConfirmed,
		}

      default:
        return null;
    }
  };

  const content = getCurrentContent();
  if (!content) return null;

  return (
    <Dialog 
	  title={content.title}
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className={classes.dialog}
    >
      <DialogContent>
	    <Typography className={classes.header} variant="h5">
          {content.header}
        </Typography>
        <Typography>
          {content.message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
	    <Button 
          className={classes.cancelButton} 
          onClick={() => content.onConfirm(false)}
        >
          {content.noButton}
        </Button>
        {content.yesButton && (
			<DialogButton
			className={classes.agreeButton}
			color="secondary"
			onClick={() => content.onConfirm(true)}
			label={content.yesButton}
			/>
		)}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
	dialog: {
	  "& .MuiDialogTitle-root h2": {
		fontSize: "1.5rem",
		fontWeight: 400
	  }
	},
	header: {
	  padding: theme.spacing(2, 0),
	},
	content: {
	  padding: theme.spacing(1, 0),
	},
	dialogActions: {
	  padding: theme.spacing(2),
	  [theme.breakpoints.down('sm')]: {
		flexDirection: 'column-reverse',
		alignItems: 'stretch',
		padding: "8px 16px",
	  }
	},
	cancelButton: {
	  border: "1px solid var(--color-dark-grey)",
	  "& .MuiButton-label": {
		textTransform: "capitalize"
	  },
	  [theme.breakpoints.down('sm')]: {
		width: '100%',
		marginLeft: '0 !important',
		marginBottom: theme.spacing(1)
	  }
	},
	agreeButton: {
	  [theme.breakpoints.down('sm')]: {
		width: '100%',
		marginLeft: '0 !important'
	  }
	}
  }));

export default TrialFlowDialog;