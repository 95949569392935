import apiConstants from "@constants/apiConstants";
import { IApiResponseWithPagination } from "@models/IPagination";
import { IEmailSubscriber, IVirtualNumber } from "@models/TextMessaging.models";
import { AxiosResponse } from "axios";
import HttpClient from "./HttpClient";

export interface ITextMessageCampaign {
	id: string;
	nanoId: string;
	codeType: "TENDLC" | "TollFree";
	campaignType: "PlatformFreeTrial" | "SoleProprietor" | "UCaaSLowVolume" | "UCaaSHighVolume";
	brandId: string;
	dca: string;
	status: "New" | "Submitted" | "Approved" | "Complete" | "Declined" | "Resubmitted" | "Removed";
	assignedTo?: {
	  id: string;
	  firstName: string;
	  lastName: string;
	  email: string;
	  username: string;
	};
}

export interface IInventoryDid {
	carrier: {
	  id: string;
	  name: string;
	};
	phoneNumber: string;
	isTollFree: boolean;
}

export default function TextMessagingService() {
  const url = apiConstants().endpoints.virtualNumbers.FOR_MESSAGING;
  const updateUrl = apiConstants().endpoints.virtualNumbers.UPDATE_NUMBER;
  const subUrl = apiConstants().endpoints.virtualNumbers.EMAIL_SUBSCRIBERS;
  const campaignsUrl = apiConstants().endpoints.textCampaigns;
  const tempTollFreeRequest = apiConstants().endpoints.tempTollFreeRequest;

  const { Get, Patch, Post, Delete } = HttpClient();

  const GetByPage = async (
    page: number,
    pageSize: number,
    forFiltering: 0 | 1 = 0
  ) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      params.set("forFiltering", String(forFiltering));
      const { data } = await Get<IApiResponseWithPagination<IVirtualNumber[]>>({
        url,
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const UpdateOne = async (id: string, direction: string) => {
    try {
      const res = await Patch<
        any,
        any,
        AxiosResponse<{ data: IVirtualNumber }>
      >({
        url: updateUrl(id),
        data: { messagingValid: true, messagingActivated: true, messagingDirection: direction },
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const UpdateDirection = async (id: string, direction: string) => {
    try {
      const res = await Patch<
        any,
        any,
        AxiosResponse<{ data: IVirtualNumber }>
      >({
        url: updateUrl(id),
        data: { messagingDirection: direction },
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const GetSubscribers = async (id: string) => {
    try {
      const res = await Get<
        any,
        AxiosResponse<IApiResponseWithPagination<IEmailSubscriber[]>>
      >({ url: subUrl(id) });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const AddSubscriber = async (id: string, email: string) => {
    try {
      const res = await Post<
        any,
        any,
        AxiosResponse<IApiResponseWithPagination<IEmailSubscriber>>
      >({
        url: subUrl(id),
        data: { email },
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const DeleteSubscriber = async (id: string, deleteId: string) => {
    try {
      const res = await Delete({ url: `${subUrl(id)}/${deleteId}` });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const GetCampaigns = async (page = 1, pageSize = 100) => {
    try {
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));

      const { data } = await Get<IApiResponseWithPagination<ITextMessageCampaign[]>>({
        url: campaignsUrl,
        params,
      });
      return data.data;
    } catch (err) {
      throw err;
    }
  };

  const RequestTempTollFreeNumber = async (): Promise<IInventoryDid[]> => {
    try {
      const { data } = await Get<{ data: IInventoryDid[] }>({
        url: tempTollFreeRequest,
      });
      return data.data; 
    } catch (err) {
      throw err;
    }
  };

  return {
    GetByPage,
    UpdateOne,
	UpdateDirection,
    GetSubscribers,
    AddSubscriber,
    DeleteSubscriber,
	GetCampaigns,
	RequestTempTollFreeNumber,
  };
}
