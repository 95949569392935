import {
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deleteConversation,
  deleteNewConversation,
  MULTIPLE_SELECTION_TYPES,
  selectConversation,
  selectMultipleConversation,
  switchConversationSelectionMode,
  updateReadStatus,
} from "@actions/conversationActions";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import uiString from "@constants/uiString";
import { DeselectIcon } from "@components/Icons/Deselect.icon";
import { UnreadIcon } from "@components/Icons/Unread.icon";
import { ReadIcon } from "@components/Icons/Read.icon";
import { BinIcon } from "@components/Icons/Bin.icon";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { NEW_CONVERSATION_ID } from "@services/dataClasses/ConversationData";
import { getEditableForNumber } from "@services/dataClasses/TalkroutePhoneNumber";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "400px",
      height: "200px",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    actionRow: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    counter: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
  })
);

export const SelectedConversations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const multipleSelectedConversations = useAppSelector(
    state => state.conversations.multipleSelectedConversations
  );
  const conversations = useAppSelector(state => state.conversations.data);
  const outgoingPhoneNumbers = useAppSelector(state => state.preferences.outgoingPhoneNumbers);

  const getIsEditable = () => {
    if (multipleSelectedConversations) {
      for (const conversationId of multipleSelectedConversations) {
        let currentPhoneNumber = "";
  
        const currentConversation = conversations.find(
          (conversation) => conversation.conversationId === conversationId
        );
  
        if (currentConversation) {
          currentPhoneNumber = currentConversation.talkrouteNumber;
        }
  
        if (!getEditableForNumber(currentPhoneNumber, outgoingPhoneNumbers)) {
          return false; // If a conversation is not editable, return false immediately
        }
      }
    }
  
    return true; // If no conversations are uneditable, return true
  };

  useEffect(() => {
    getIsEditable();
  }, [multipleSelectedConversations, conversations, outgoingPhoneNumbers]);

  useEffect(() => {
    if (multipleSelectedConversations?.length === 0) deselectAllEntries();
  }, [multipleSelectedConversations?.length]);

  const deselectAllEntries = () => {
    dispatch(switchConversationSelectionMode(false));
    dispatch(selectMultipleConversation("", MULTIPLE_SELECTION_TYPES.NONE));
  };

  const deleteSelectedConversations = () => {
    for (const convo of multipleSelectedConversations) {
      if (convo !== null && convo !== "Talkroute") {
        if (convo === NEW_CONVERSATION_ID) {
          dispatch(deleteNewConversation());
        } else {
          dispatch(deleteConversation(convo));
        }
      }
    }
    dispatch(selectConversation("Talkroute"));
    dispatch(switchConversationSelectionMode(false));
  };

  const markAsReadSelectedConversations = () => {
    for (const convo of multipleSelectedConversations) {
      if (convo !== null && convo !== "Talkroute" && convo !== NEW_CONVERSATION_ID) {
        dispatch(
          updateReadStatus({
            conversationId: convo,
            readStatus: true,
          })
        );
      }
    }
    dispatch(selectConversation("Talkroute"));
    dispatch(selectMultipleConversation("", MULTIPLE_SELECTION_TYPES.NONE));
    dispatch(switchConversationSelectionMode(false));
  };

  const markAsUnreadSelectedConversations = () => {
    for (const convo of multipleSelectedConversations) {
      if (convo !== null && convo !== "Talkroute" && convo !== NEW_CONVERSATION_ID) {
        dispatch(
          updateReadStatus({
            conversationId: convo,
            readStatus: false,
          })
        );
      }
    }
    dispatch(selectConversation("Talkroute"));
    dispatch(selectMultipleConversation("", MULTIPLE_SELECTION_TYPES.NONE));
    dispatch(switchConversationSelectionMode(false));
  };

  const selectedConversationCount = multipleSelectedConversations?.filter(
    (i) => !!i
  ).length;
  return (
    <Paper className={classes.root}>
      <div className={classes.counter}>
        <Typography variant="h3" align="center">
          {selectedConversationCount}
        </Typography>
        <Typography variant="h6" align="center">
          Messages Selected
        </Typography>
      </div>
      <div className={classes.actionRow}>
        <Tooltip title="Delete">
          <IconButton
            style={{
              color: "var(--color-bright-light-blue)",
              opacity: getIsEditable() ? 1 : .45
            }}
            aria-label="delete conversations"
            component="span"
            onClick={() => setOpenDeleteDialog(true)}
            disabled={!getIsEditable()}
          >
            <BinIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mark as Read">
          <IconButton
            style={{
              color: "var(--color-bright-light-blue)",
              opacity: getIsEditable() ? 1 : .45
            }}
            aria-label="mark as read"
            component="span"
            onClick={markAsReadSelectedConversations}
            disabled={!getIsEditable()}
          >
            <UnreadIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mark as Unread">
          <IconButton
            style={{
              color: "var(--color-bright-light-blue)",
              opacity: getIsEditable() ? 1 : .45
            }}
            aria-label="mark as unread"
            component="span"
            onClick={markAsUnreadSelectedConversations}
            disabled={!getIsEditable()}
          >
            <ReadIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Deselect">
          <IconButton
            style={{color: "var(--color-bright-light-blue)"}}
            aria-label="deselect"
            component="span"
            onClick={deselectAllEntries}
          >
            <DeselectIcon fontSize="default" />
          </IconButton>
        </Tooltip>
      </div>
      <ConfirmDialog
        header={uiString.CONVERSATION_DELETE_CONFIRMATION_HEADER}
        content={
          selectedConversationCount > 1
            ? uiString.MULTIPLE_CONVERSATIONS_DELETE_CONFIRMATION_BODY
            : uiString.CONVERSATION_DELETE_CONFIRMATION_BODY
        }
        open={openDeleteDialog}
        confirmButtonLabel={uiString.DELETE}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={deleteSelectedConversations}
      />
    </Paper>
  );
};
