import React, { FC, useEffect, useState } from "react";
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import { formatPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import uiString from "@constants/uiString";

const TITLE = "Request Local Text Messaging";
const TITLE2 = "IMPORTANT!!!";
const TITLE3 = "Local Number Text Registration Process";
const TITLE4 = "Local Text Messaging";
const TITLE5 = "Text Messaging Currently Inactive";

const HEADER = (number: string) => `Activate Text Messaging for ${number}`;

const CONTENT =
  "As of 09/30/2024, new carrier regulations mandate that all local phone numbers must be registered before text messages can be sent or received. These new regulations impact all service providers, including Talkroute.";
const CONTENT2 = "This process takes 3-7 business days to complete on average.";
const CONTENT3 = "Please note that, depending on your plan and texting usage, additional one-time and/or monthly fees may be required.";
const CONTENT4 = "Next, we will provide you with steps to register your local phone number.";
const CONTENT8 = "Please note that if your registration has already been approved, no further action is required. Text messaging will be automatically activated for this number within 1-3 business days.";

const CONTENT5 = "Step #1: Follow the instructions in ";
const CONTENT6 = "Step #2: Talkroute will submit your registration to the carrier(s)";
const CONTENT7 = "Step #3: You will be notified by email when your verification is approved";
const CONTENT9 = "You Have Not Enabled Text Messaging for Your Account";
const CONTENT10 = "You can enable text messaging by visiting the settings page and clicking enable next to the desired virtual phone number(s).";

const TERMS = {
	CHECK_DESC_1:
	  "I understand that once verification is submitted, it typically takes 3-7 business days for approval, but this may take longer if my submission requires additional clarification or is denied.",
	CHECK_DESC_2: 
	  "I understand that Talkroute does not approve or deny my verification request and the decision is solely at the discretion of the carrier(s).",
	CHECK_DESC_3: 
	  "I understand that I will not be able to send or receive text messages with my phone number until the registration is approved.",
	CHECK_DESC_4:
	  "I agree to the Talkroute's ",
};

const MODAL_TERMS_CHECKBOX = "text-message-activation";

type IProps = {
  open: boolean;
  phoneNumber?: string;
  onClose: () => void;
  onSubmit: (direction: string) => void;
  initialSlide?: number;
};

export const ActivationDialog: FC<IProps> = ({
  onClose,
  onSubmit,
  open,
  phoneNumber,
  initialSlide = 0,
}) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) {
      setCurrentSlide(initialSlide);
	  if (initialSlide !== 2) {
        setCheckboxes({
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: false,
        });
	}
    }
  }, [open, initialSlide]);

  const handleNext = () => {
    if (currentSlide < 2) {
      setCurrentSlide(currentSlide + 1);
    } else {
      onSubmit('Inbound and Outbound');
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const allChecked = Object.values(checkboxes).every(Boolean);

  const getDialogTitle = () => {
    switch (currentSlide) {
      case 0: return TITLE;
      case 1: return TITLE2;
      case 2: return TITLE3;
	  case 3: return TITLE4;
	  case 4: return TITLE5;
      default: return "";
    }
  };

  const getButtonLabel = () => {
    switch (currentSlide) {
      case 0: return 'Activate Text Messaging';
      case 1: return 'I Wish To Proceed And Verify My Number';
      case 2: return 'Confirm';
	  case 4: return 'Go To Settings';
      default: return "";
    }
  };

  const renderSlideContent = (slideNumber: number) => {
    const formattedPhoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber.replace("+", "")) : "";

    switch (slideNumber) {
	  case 0:
		return (
			<>
				<Typography className={classes.header} variant="h5">
				{HEADER(formattedPhoneNumber)}
				</Typography>
          	</>
		);
      case 1:
        return (
          <>
            <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT}</Typography>
            <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT2}</Typography>
            <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT3}</Typography>
            <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT4}</Typography>
			<Typography className={[classes.content, classes.bold, classes.italic].join(' ')}>{CONTENT8}</Typography>
          </>
        );
      case 2:
        return (
          <>
            <Typography className={[classes.content, classes.bold].join(' ')}>
				{CONTENT5}
				<Link
                        href="https://talkroute.info/10dlc"
                        target="_blank"
                    >
                        {"instructions in this tutorial."}
				</Link>
			</Typography>
            <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT6}</Typography>
            <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT7}</Typography>
			<FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {TERMS.CHECK_DESC_1}
                        </span>
                    </>
                    }
                    control={
                        <Checkbox
                            id={`${MODAL_TERMS_CHECKBOX}_1`}
                            checked={checkboxes.checkbox1}
                            onChange={handleCheckboxChange}
							name="checkbox1"
                            color="primary"
                        />
                    }
			/>
			<FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {TERMS.CHECK_DESC_2}
                        </span>
                    </>
                    }
                    control={
                        <Checkbox
                            id={`${MODAL_TERMS_CHECKBOX}_2`}
                            checked={checkboxes.checkbox2}
                            onChange={handleCheckboxChange}
							name="checkbox2"
                            color="primary"
                        />
                    }
			/>
			<FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {TERMS.CHECK_DESC_3}
                        </span>
                    </>
                    }
                    control={
                        <Checkbox
                            id={`${MODAL_TERMS_CHECKBOX}_3`}
                            checked={checkboxes.checkbox3}
                            onChange={handleCheckboxChange}
							name="checkbox3"
                            color="primary"
                        />
                    }
			  />
			  <FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {TERMS.CHECK_DESC_4}
                        </span>
						<Link
                            href="https://talkroute.com/terms-and-conditions/"
                            target="_blank"
                        >
                            {uiString.initialModal.termsModal.TITLE}
                        </Link>
                    	</>
                    }
                    control={
                        <Checkbox
                            id={`${MODAL_TERMS_CHECKBOX}_4`}
                            checked={checkboxes.checkbox4}
                            onChange={handleCheckboxChange}
							name="checkbox4"
                            color="primary"
                        />
                    }
			  />
          </>
        );
	  case 3:
        return (
          <>
            <Typography className={classes.header} variant="h5">
              Your Text Activation for {formattedPhoneNumber} is now pending
            </Typography>
            <Typography className={classes.content}>
              Don't forget to submit your 10DLC Registration as soon as possible by following the 
			  <Link
					href="https://talkroute.info/10dlc"
					target="_blank"
				>
					{" instructions in this tutorial."}
				</Link>
            </Typography>
            <Typography className={classes.content}>
              If you have already submitted your registration be sure to monitor your email for further updates.
            </Typography>
            <Typography className={classes.content}>
              <strong>Have additional questions? Feel free to contact support by emailing support@talkroute.com or by calling 1 (800) 747-2140.</strong>
            </Typography>
          </>
        );
	  case 4:
		return (
			<>
				<Typography className={classes.header} variant="h5">
					{CONTENT9}
				</Typography>
				<Typography className={classes.content}>{CONTENT10}</Typography>
			</>
		  );
      default:
        return null;
    }
  };

  return (
    <Dialog 
		open={open} 
		title={getDialogTitle()} 
		onClose={handleClose} 
		className={classes.root}>
      <DialogContent>
        {renderSlideContent(currentSlide)}
      </DialogContent>
	  <DialogActions className={classes.dialogActions}>
		{currentSlide === 3 ? (
			<Button className={`${classes.cancelButton} ${classes.button}`} onClick={handleClose}>
			Close
			</Button>
		) : (
			<>
			<Button className={`${classes.cancelButton} ${classes.button}`} onClick={handleClose}>
				{uiString.updateStrings.CANCEL}
			</Button>
			<DialogButton
				className={`${classes.agreeButton} ${classes.button}`}
				color="secondary"
				onClick={handleNext}
				label={getButtonLabel()}
				disabled={currentSlide === 2 && !allChecked}
			/>
			</>
		)}
	  </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(2, 0),
  },
  content: {
    padding: theme.spacing(1, 0),
  },
  cancelButton: {
    border: "1px solid var(--color-dark-grey)",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
   root : {
    "& .MuiDialogTitle-root h2" : {
      fontSize: "1.5rem",
      fontWeight: 400
    },
    "& .MuiTypography-body1": {
      color: "#6e6e6e",
    }
  },
    termsLabel: {
      fontSize: "1rem",
      padding: "1em 0 0",
    },
  agreeButton: {
    padding: "6px 8px"
  },
  italic: {
	fontStyle: "italic"
  },
  bold: {
	fontWeight: "bold"
  },
	red: {
		color: "red !important",
		"&$red": {
			color: "red !important",
		},
	},
	dialogActions: {
		[theme.breakpoints.down('sm')]: {
		  flexDirection: 'column-reverse',
		  alignItems: 'stretch',
		  padding: "2px 15px !important",
		},
	},
	button: {
		[theme.breakpoints.down('sm')]: {
		  marginBottom: theme.spacing(1),
		  width: '100%',
		  marginLeft: '0 !important',
		},
	},
}));
