import React, { FC, useCallback, useEffect, useRef, useState } from "react";

// MUI
import { Grid, IconButton, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Dimmer, Loader as SemanticLoader } from "semantic-ui-react";

// Components
import Loader from "@components/PresentationComponents/Loader";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { DisplayRowIndicator } from "@helpers/components/DisplayRowIndicator";
import { ActivationDialog } from "./ActivationDialog";
import AddEmailDialog from "./AddEmailDialog";

// Hooks
import { formatPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { useToast } from "@helpers/hooks/useToast.hook";
import clsx from "clsx";

// Services
import TextMessagingService from "@services/TextMessaging.service";
import UserService from "@services/User.service";
import { AxiosError } from "axios";

// Models
import { FeatureIdsEnum, IFeature } from "@models/Account.models";
import { IEmailSubscriber, IVirtualNumber } from "@models/TextMessaging.models";
import { IPagination } from "@models/IPagination";

// Icons
import EnvelopeIcon from "@resources/icons/envelope.svg";
import BubbleTextIcon from "@resources/icons/bubble-text.svg";
import { Check } from "@material-ui/icons";

// Constants
import { ComponentDimensions } from "@constants/appConstants";
import uiString from "@constants/uiString";
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import ActivationSwitch from "./ActivationSwitch";
import TollFreeActivationDialog from "./TollFreeActivationDialog";

import { TextMessagingProvider, useTextMessaging } from './TextMessagingContext';
import TrialFlowDialog from './TrialFlowDialog';
import SimpleActivationDialog from "./SimpleActivationDialog";
import { MODAL_STEPS } from "./TextMessagingConstants";

const textStrings = uiString.SETTINGS_AND_PREF.TEXT_MESSAGING;

const TextMessagingContent: FC = () => {
  // Component State
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [emailIsLoading, setEmailIsLoading] = useState("");
  const [openActivationDialog, setOpenActivationDialog] = useState(false);
  const [initialStepForDialog, setInitialStepForDialog] = useState(0);
  const [initialStepForActivationDialog, setInitialStepForActivationDialog] = useState(0);
  const [openTollFreeActivationDialog, setOpenTollFreeActivationDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [
    currentVirtualNumber,
    setCurrentVirtualNumber,
  ] = useState<IVirtualNumber | null>(null);
  const [userEmails, setUserEmails] = useState<IEmailSubscriber[]>([]);
  const [unlimitedMessagingPlan, setUnlimitedMessagingPlan] = useState<IFeature | null>(null);
  const [textMessagePlan, setTextMessagePlan] = useState<IFeature | null>(null);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const [virtualNumbers, setVirtualNumbers] = useState<IVirtualNumber[]>([]); 
  const componentIsLoaded = useRef<boolean>();

  const [showTrialFlow, setShowTrialFlow] = useState(false);
  const [trialFlowInitialStep, setTrialFlowInitialStep] = useState<string | undefined>(undefined);
  const [showSimpleActivationDialog, setShowSimpleActivationDialog] = useState(false);
  const { featureFlags } = useTextMessaging();


  // App State
  const rowsPerPage = useAppSelector(
    (state) => state.preferences.tableSize.textMessaging
  );
  const accountId = useAppSelector((state) => state.authentication.accountId);

  // Hooks
  const classes = useStyles();
  const { setToastError } = useToast();
  const { GetPermittedFeatures } = UserService();
  const {
    GetByPage,
    UpdateOne,
	UpdateDirection,
    GetSubscribers,
    AddSubscriber,
    DeleteSubscriber,
  } = TextMessagingService();


  useEffect(() => {
    componentIsLoaded.current = true;
    permittedFeaturesGET();
    return () => {
      componentIsLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    virtualNumbersGET();
  }, [rowsPerPage]);



  const mapForwardedNumberToTableData = (data: IVirtualNumber) => {
    return {...data,
    [textStrings.TABLE_COL_LABELS[0]]: formatPhoneNumber(
      data.phoneNumber.replace("+", "")
    ),
    }
  };


  const permittedFeaturesGET = async () => {
    try {
      setIsLoading(true);
      if (accountId) {
        const { data } = await GetPermittedFeatures(String(accountId));
        const unlimitedTextMessagingFeature = data.find(
          (item) => item.feature_id === FeatureIdsEnum.TEXT_MESSAGING
        );
        const textMessagingFeature = data.find(
          (item) => item.feature_id === FeatureIdsEnum.TEXT_MESSAGES
        );
        if (unlimitedTextMessagingFeature) {
          setUnlimitedMessagingPlan(unlimitedTextMessagingFeature);
        }
        if (textMessagingFeature) {
          setTextMessagePlan(textMessagingFeature);
        }
      }
    } catch (error) {
      setFirstLoad(false);
      setIsLoading(false);
      setToastError(error as AxiosError);
    }
  };

  const virtualNumbersGET = async (page?: number) => {
    try {
      setIsLoading(true);
      const { data, pagination } = await GetByPage(
        page ?? pageState?.currentPage ?? 1,
        rowsPerPage
      );
      // sussing out toll free
      const tollFreeAreaCodes = ["800", "888", "877", "866", "855", "844", "833"];
      data.forEach((virtualNumber: IVirtualNumber & { tollFree?: boolean }) => {
        const areaCode = virtualNumber.phoneNumber.substring(2, 5);
        virtualNumber.tollFree = tollFreeAreaCodes.includes(areaCode);
      });
      if (componentIsLoaded) {
        setVirtualNumbers(data);
        setPageState(pagination);
        setFirstLoad(false);
        setIsLoading(false);
      }
    } catch (error) {
      setFirstLoad(false);
      setIsLoading(false);
      setToastError(error as AxiosError);
    }
  };



  const activationHandler = useCallback(async (direction: string) => {
    try {
      if (currentVirtualNumber) {
        const response = currentVirtualNumber.textMessagingAllowed ? await UpdateDirection(String(currentVirtualNumber.id), direction) : await UpdateOne(String(currentVirtualNumber.id), direction);
        if (response.status === 402) {
        } else {
          // sussing out toll free
          const tollFreeAreaCodes = ["800", "888", "877", "866", "855", "844", "833"];
          const areaCode = response.data.data.phoneNumber.substring(2, 5);
          const isTollFree = tollFreeAreaCodes.includes(areaCode);
          response.data.data.tollFree = isTollFree;
          
          setVirtualNumbers((prevState) => {
            const index = prevState.findIndex(
              (item) => item.id === response.data.data.id
            );
            const newState = prevState;
            newState[index] = response.data.data;
            return newState;
          });

          setOpenActivationDialog(false);
        }
      }
    } catch (err) {
      setToastError(err as AxiosError);
    }
  }, [currentVirtualNumber]);

  const activationDialogOpenHandler = useCallback((virtual: IVirtualNumber, pending: boolean, initialStep?: number, incomingActivated?: boolean) => {
      setCurrentVirtualNumber(virtual);

	  const isIncomingOnly = virtual.messagingDirection === "Inbound Only";
	  const isTrial = featureFlags.isTrialAccount;
	  const isCampaign = featureFlags.isCampaignExists;

	  if (!pending) {
		if (isTrial) {
		    setShowTrialFlow(true);
        	setTrialFlowInitialStep(MODAL_STEPS.REGISTER_NUMBER); 
		} else if (isIncomingOnly && !isCampaign) {
			if (incomingActivated) {
				setShowTrialFlow(true);
				setTrialFlowInitialStep(MODAL_STEPS.OUTGOING_REGISTER);
		    } else {
				setShowTrialFlow(true);
				setTrialFlowInitialStep(MODAL_STEPS.ACCOUNT_REGISTER);
			}
		} else if (isIncomingOnly && isCampaign) {
			setShowSimpleActivationDialog(true);
		} else if (!isIncomingOnly && !isCampaign) {
			setShowTrialFlow(true);
			setTrialFlowInitialStep(MODAL_STEPS.ACCOUNT_REGISTER);
		} else if (isCampaign) {
			setShowSimpleActivationDialog(true);
		} else {
		  setInitialStepForActivationDialog(initialStep || 0);
		  setOpenActivationDialog(true);
		}
	  } else {
		  setInitialStepForActivationDialog(initialStep || 0);
		  setOpenActivationDialog(true);
	  }
  }, [featureFlags.isTrialAccount, featureFlags.isCampaignExists]);

  const tollFreeActivationDialogOpenHandler = useCallback((virtual: IVirtualNumber, pending: boolean, initialStep?: number, incomingActivated?: boolean) => {
      setCurrentVirtualNumber(virtual);
	  const isIncomingOnly = !virtual.textMessagingAllowed;
	  const isTrial = featureFlags.isTrialAccount;
	  const isCampaign = featureFlags.isCampaignExists;

	  if (!pending) {
		if (isTrial) {
			setShowTrialFlow(true);
			setTrialFlowInitialStep(MODAL_STEPS.REGISTER_NUMBER);
		} else if (isIncomingOnly && !isCampaign) {
			if (incomingActivated) {
				setShowTrialFlow(true);
				setTrialFlowInitialStep(MODAL_STEPS.OUTGOING_REGISTER);
		    } else {
				setShowTrialFlow(true);
				setTrialFlowInitialStep(MODAL_STEPS.ACCOUNT_REGISTER);
			}
		} else if (isIncomingOnly && isCampaign) {
			setOpenTollFreeActivationDialog(true);
		} else {
		  setInitialStepForDialog(initialStep || 0);
		  setOpenTollFreeActivationDialog(true);
		}
	  } else {
		setInitialStepForDialog(initialStep || 0);
		setOpenTollFreeActivationDialog(true);
	  }
  }, [featureFlags.isTrialAccount]);

  const handleTrialFlowClose = useCallback(() => {
    setShowTrialFlow(false);
    if (currentVirtualNumber && !currentVirtualNumber.textMessagingAllowed) {
		setCurrentVirtualNumber(null);
	}
  }, [currentVirtualNumber]);

  const handleSimpleActivationClose = useCallback(() => {
	setShowSimpleActivationDialog(false);
	setCurrentVirtualNumber(null);
  }, []);

  const handleContinueToExistingFlow = useCallback(() => {
    setShowTrialFlow(false);

    if (currentVirtualNumber?.tollFree) {
      setOpenTollFreeActivationDialog(true);
    } else {
      setOpenActivationDialog(true);
    }
  }, [currentVirtualNumber]);

  const activationDialogCloseHandler = useCallback(
    () => setOpenActivationDialog(false),
    []
  );

  const tollFreeActivationDialogCloseHandler = useCallback(
    () => setOpenTollFreeActivationDialog(false),
    []
  );

  const emailDialogOpenHandler = useCallback(
    async (virtual: IVirtualNumber) => {
      try {
        setEmailIsLoading(String(virtual.id));
        setCurrentVirtualNumber(virtual);
        const { data } = await GetSubscribers(String(virtual.id));
        setOpenEmailDialog(true);
        setUserEmails(data.data);
        setEmailIsLoading("");
      } catch (err) {
        setEmailIsLoading("");
        setToastError(err as AxiosError);
      }
    },
    []
  );

  const emailDialogCloseHandler = useCallback(
    () => setOpenEmailDialog(false),
    []
  );

  const addEmail = useCallback(
    async (email: string) => {
      try {
        if (currentVirtualNumber) {
          const { data } = await AddSubscriber(
            String(currentVirtualNumber.id),
            email
          );
          setUserEmails((prevState) => [data.data, ...prevState]);
          return data;
        }
      } catch (error) {
        setToastError(error as AxiosError);
      }
    },
    [currentVirtualNumber]
  );

  const deleteHandler = useCallback(async (id: string, virtualId: string) => {
    try {
      await DeleteSubscriber(virtualId, id);
      setUserEmails((prevState) => prevState.filter((item) => item.id !== id));
    } catch (error) {
      setToastError(error as AxiosError);
    }
  }, []);

  const upgradeHandler = useCallback(
    () =>
      window.open(
        "https://testing-cp.talkroute.com/account#!/accountInformation",
        "_blank"
      ),
    []
  );

  const handlePageChange = useCallback(
    (page: number) => virtualNumbersGET(page),
    []
  );

  return (
    <>
      <Loader open={isLoading} />
      <UnderlinedTitle>{textStrings.TITLE}</UnderlinedTitle>
      {!firstLoad && (
        <Grid className={classes.content}>
          <UnderlinedTitle className={classes.title}>
            {textStrings.CURRENT_USAGE}
          </UnderlinedTitle>
          <Grid className={clsx(classes.box, classes.heading)}>
            {unlimitedMessagingPlan && textMessagePlan && (
              <>
                {unlimitedMessagingPlan?.allowed && (
                  <>
                    <Typography>
                      Your plan includes unlimited text messages
                    </Typography>
                    <Typography>
                      You have sent and received {unlimitedMessagingPlan.used} messages this
                      billing cycle
                    </Typography>
                    <DisplayRowIndicator
                      initial={unlimitedMessagingPlan.used}
                      max={unlimitedMessagingPlan.included}
                    />
                  </>
                )}
                {!unlimitedMessagingPlan?.allowed && (
                  <>
                    <Typography>
                      You have sent and received {textMessagePlan.used} messages this
                      billing cycle
                    </Typography>
                    <DisplayRowIndicator
                      initial={textMessagePlan.used}
                      max={textMessagePlan.included}
                    />
                    <Typography className={classes.blueText}>
                      Text Messages that are longer than 160 characters will use
                      multiple text messages in your current billing cycle.
                    </Typography>
                    <InputButton onClick={upgradeHandler} color="primary">
                      Upgrade to Unlimited Messages
                    </InputButton>
                  </>
                )}
              </>
            )}
          </Grid>
          <CustomTablePagination
            pagination={pageState}
            handlePageChange={handlePageChange}
            storeKey="textMessaging"
          >
            <PlainTable
              data={virtualNumbers}
              selectColumns={textStrings.TABLE_COL_LABELS}
              columnSize={["200px"]}
              colSettings={[
                {
                  index: 2,
                  centerHeader: true,
                },
              ]}
              dataMapper={mapForwardedNumberToTableData}
              actions={[
                // Uncomment the bubble icon when feature is activated
                // {
                //   colLabel: textStrings.AUTO_RESPONDER,
                //   width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                //   ActionComponent: (row) => (
                //     <IconButton key={row.id} onChange={() => {}}>
                //       <BubbleTextIcon />
                //     </IconButton>
                //   ),
                // },
                {
                  colLabel: textStrings.EMAIL_NOTIFICATIONS,
                  width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                  ActionComponent: (row) => (
                    <IconButton
                      key={row.id}
                      onClick={() => emailDialogOpenHandler(row)}
                    >
                      {emailIsLoading === String(row.id) ? (
                        <Dimmer
                          style={{
                            backgroundColor: "transparent",
                            cursor: "default",
                            padding: 0,
                          }}
                          active
                          inverted
                        >
                          <SemanticLoader inverted size="small" />
                        </Dimmer>
                      ) : (
                        <EnvelopeIcon />
                      )}
                    </IconButton>
                  ),
                },
                {
                  colLabel: textStrings.ENABLE,
                  width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                  ActionComponent: (row) => {
                    const enhancedRow = row as IVirtualNumber & { tollFree?: boolean };
                    const handler = enhancedRow.tollFree ? tollFreeActivationDialogOpenHandler : activationDialogOpenHandler;
                    return (
						<ActivationSwitch 
						  row={enhancedRow} 
						  activationDialogHandler={handler} 
						  isCampaign={featureFlags.isCampaignExists}
						/>
					);
                  },
                },
              ]}
              fullWidth
            />
          </CustomTablePagination>
        </Grid>
      )}
	  <SimpleActivationDialog
		open={showSimpleActivationDialog}
		onClose={handleSimpleActivationClose}
		onSubmit={activationHandler}
		currentNumber={currentVirtualNumber}
	  />
	  <TrialFlowDialog
        open={showTrialFlow}
        onClose={handleTrialFlowClose}
        currentNumber={currentVirtualNumber}
		activationHandler={activationHandler}
        onContinueToExistingFlow={handleContinueToExistingFlow}
		initialStep={trialFlowInitialStep}
		refreshVirtualNumbers={virtualNumbersGET}
		hasTempNumber={virtualNumbers.some(v => v.isTextTemporaryNumber)}
      />
      <ActivationDialog
        open={openActivationDialog}
        onSubmit={activationHandler}
        onClose={activationDialogCloseHandler}
        phoneNumber={currentVirtualNumber?.phoneNumber}
		initialSlide={initialStepForActivationDialog}
      />
      <AddEmailDialog
        open={openEmailDialog}
        onClose={emailDialogCloseHandler}
        userEmails={userEmails}
        addEmail={addEmail}
        deleteHandler={deleteHandler}
        virtualNumber={currentVirtualNumber}
      />
      <TollFreeActivationDialog 
        open={openTollFreeActivationDialog}
        onSubmit={activationHandler}
        onClose={tollFreeActivationDialogCloseHandler}
        phoneNumber={currentVirtualNumber?.phoneNumber}
        initialStep={initialStepForDialog}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
  },
  title: {
    fontSize: "1.3rem",
    margin: theme.spacing(4, 0),
  },
  heading: {
    textAlign: "center",
    padding: "5%",
    marginBottom: theme.spacing(8),
  },
  box: {
    border: "1px solid var(--color-dark-grey)",
    borderRadius: theme.shape.borderRadius,
    minHeight: "1rem",
  },
  blueText: {
    color: theme.palette.common.darkBlue,
    backgroundColor: theme.palette.common.veryLightBlue,
    border: `1px solid ${theme.palette.common.lightBlue}`,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
  },
}));

const TextMessaging: FC = () => {
	return (
	  <TextMessagingProvider>
		<TextMessagingContent />
	  </TextMessagingProvider>
	);
  };
  
export default TextMessaging;