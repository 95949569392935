import React, { createContext, useContext, useEffect, useState } from 'react';
import { TextMessagingFeatureFlags } from '@models/TextMessaging.models';
import { useAppSelector } from '@helpers/hooks/useAppSelector.hook';

interface TextMessagingContextType {
  featureFlags: TextMessagingFeatureFlags;
  setFeatureFlags: (flags: Partial<TextMessagingFeatureFlags>) => void;
}

const TextMessagingContext = createContext<TextMessagingContextType | undefined>(undefined);

export const TextMessagingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<TextMessagingFeatureFlags>({
    isTrialAccount: true, 
	isCampaignExists: false,
  });

  const isTrial = useAppSelector(state => state.authentication.isTrial);
  const isCampaignExists = useAppSelector((state) => state.authentication.isCampaignExists);

  const updateFlags = (newFlags: Partial<TextMessagingFeatureFlags>) => {
    setFeatureFlags(prev => ({
      ...prev,
      ...newFlags
    }));
  };

  useEffect(() => {
    setFeatureFlags(prev => ({
      ...prev,
      isTrialAccount: isTrial,
      isCampaignExists,
    }));
  }, [isTrial, isCampaignExists]);

  return (
    <TextMessagingContext.Provider value={{ featureFlags, setFeatureFlags: updateFlags }}>
      {children}
    </TextMessagingContext.Provider>
  );
};

export const useTextMessaging = () => {
	const context = useContext(TextMessagingContext);
	if (!context) {
	  throw new Error('useTextMessaging must be used within TextMessagingProvider');
	}
	return context;
};
