export const MODAL_STEPS = {
	// Trial flow steps
	REGISTER_NUMBER: 'REGISTER_NUMBER',
	END_TRIAL: 'END_TRIAL',
	ACCOUNT_REGISTER: 'ACCOUNT_REGISTER',
	TEMP_NUMBER: 'TEMP_NUMBER',
	INCOMING_ONLY: 'INCOMING_ONLY',
	TEMP_CONFIRM: 'TEMP_CONFIRM',
	TEMP_ERROR: 'TEMP_ERROR',
	END_TRIAL_ERROR: 'END_TRIAL_ERROR',
	OUTGOING_REGISTER: 'OUTGOING_REGISTER',
  } as const;
  
  export const MODAL_TITLES = {
	REGISTER_NUMBER: {
	  TOLLFREE: 'Register Toll-Free Number',
	  LOCAL: 'Text Messaging Activation Requires Registration',
	  LOCAL_PROCEED: 'Register & Activate Local Number',
	},
	END_TRIAL: 'End Free Trial Period Early',
	ACCOUNT_REGISTER: 'Account Registration',
	TEMP_NUMBER: {
	  TOLLFREE: 'Optional: Temporary Toll-Free Phone Number',
	},
	INCOMING_ONLY: 'Text Messaging Activation',
	TEMP_CONFIRM: 'Optional: Temporary Toll-Free Phone Number',
	TEMP_ERROR: 'Unable to Issue Temporary Number',
	END_TRIAL_ERROR: 'Failed to End Free Trial',
	OUTGOING_REGISTER: 'Account Registration',
  } as const;

  export const MODAL_HEADERS = {
	END_TRIAL: 'WARNING: This Will End Your Free Trial',
	TEMP_NUMBER: 'Not Ready to Register & Activate?',
	ACCOUNT_REGISTER: 'Registration Required for Outgoing Text Messaging',
	INCOMING_ONLY: 'Incoming Only Text Messaging Activation',
	TEMP_CONFIRM: 'Temporary Toll-Free Number Pending',
	TEMP_ERROR: 'Error Issuing Temporary Number',
	END_TRIAL_ERROR: 'Error Ending Free Trial',
	OUTGOING_REGISTER: 'This Number is Incoming Only'
  }

  export const MODAL_BUTTONS = {
	END_TRIAL_NO: 'No, do not end my free trial early',
	END_TRIAL_YES: 'Yes, end my free trial early',
	ACCOUNT_REGISTER_NO: 'I only want to receive text messages',
	ACCOUNT_REGISTER_YES: 'I want to send & receive text messages',
	TEMP_NUMBER_NO: 'No, I do not wish to try text messaging',
	TEMP_NUMBER_YES: 'Yes, I want to try text messaging',
	OUTGOING_REGISTER_NO: 'No, I only want to receive text messages',
	OUTGOING_REGISTER_YES: 'Yes, I want to try text messaging',
  }
  
  export const MODAL_MESSAGES = {
	REGISTER_NUMBER: 'Would you like to proceed with registering your account and activating text messaging for this phone number?',
	ACCOUNT_REGISTER: 'Due to new industry wide regulations all accounts are required to register in order to send outgoing text messages. Registration is NOT required if you only wish to receive incoming text messages.',
	END_TRIAL: 'Registering your account and activating text messaging requires a paid account. Proceeding will end your free trial early and immediately charge the payment method on file.',
	TEMP_NUMBER: 'No problem! If you wish to try text messaging prior to registering your account and activating your phone number, we can provide you with a temporary toll-free phone number that is already text enabled.',
	INCOMING_ONLY: 'Your text messaging activation request has been received. Please allow 1-3 business days for your number to become active across all networks.',
	TEMP_CONFIRM: 'We are now working to add a temporary toll-free number to your account so that you may try text messaging. We will send you an email as soon as it is ready.',
	TEMP_ERROR: 'We were unable to issue a temporary toll-free number for you. Please contact support.',
	END_TRIAL_ERROR: 'We were unable to end your free trial at this time. Please contact support or try again.',
	OUTGOING_REGISTER: 'This number is currently incoming-only. Due to new industry wide regulations all accounts are required to register in order to send outgoing text messages. Registration is NOT required if you wish to continue receiving incoming text messages only.',
  } as const;