import React, { FC } from "react";
import { Check } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { IVirtualNumber } from "@models/TextMessaging.models";

interface ActivationSwitchProps {
	row: IVirtualNumber;
	activationDialogHandler: (...args: any[]) => void;
	isCampaign?: boolean;
}

const ActivationSwitch: FC<ActivationSwitchProps> = ({row, activationDialogHandler, isCampaign}) => {
    const classes = useStyles();
      if (row.messagingActivated === false && row.messagingActivationRequestedAt === null) {
        return (
          <button
            onClick={() => activationDialogHandler(row, false)}
            className={classes.switch}
          >
            <div className={classes.switchOn}>ON</div>
          </button>
        );
      } else if (
        row.messagingActivated === false && row.messagingActivationRequestedAt !== null
      ) {
		if (!row.textMessagingAllowed && row.messagingDirection === 'Inbound Only' && !isCampaign) {
			return (
				<p
					onClick={() => activationDialogHandler(row, false, null, true)}
					className={classes.pendingLink}
				>
					Incoming Only
				</p>
			);
		} else if (!row.textMessagingAllowed && row.messagingDirection === 'Inbound Only' && isCampaign) {
			return (
				<p
					onClick={() => activationDialogHandler(row, false, null, true)}
					className={classes.pendingLink}
				>
					Incoming Only
				</p>
			);
		} else if (!row.textMessagingAllowed && row.messagingDirection === 'Inbound and Outbound' && isCampaign) {
			return (
				<p
					onClick={() => activationDialogHandler(row, true, 3, false)}
					className={classes.pendingLink}
				>
					Pending
				</p>
			);
		} 

        if (row.tollFree) {
          return (
              <p
                  onClick={() => activationDialogHandler(row, true, 3, false)}
                  className={classes.pendingLink}
              >
                  Pending
              </p>
          );
        } else {
            return (
				<p
					onClick={() => activationDialogHandler(row, true, 3, false)}
					className={classes.pendingLink}
				>
					Pending
				</p>
			);
        }
      } else if (!row.textMessagingAllowed) {
		return (
			<p
				onClick={() => activationDialogHandler(row, false, null, true)}
				className={classes.pendingLink}
			>
				Incoming Only
			</p>
		);
	  } else if (row.textMessagingAllowed && row.messagingDirection === 'Inbound Only' && !isCampaign) {
		return (
			<p
				onClick={() => activationDialogHandler(row, false, null, true)}
				className={classes.incomingOnlyActivated}
			>
				Incoming Only
			</p>
		);
	  } else if (row.textMessagingAllowed && row.messagingDirection === 'Inbound and Outbound' && !isCampaign && !row.isTextTemporaryNumber) {
		return (
			<p
				onClick={() => activationDialogHandler(row, true, 3, false)}
				className={classes.pendingLink}
			>
				Pending
			</p>
		);
	  } else if (row.textMessagingAllowed && row.messagingDirection === 'Inbound Only' && isCampaign) {
		return (
			<p
				onClick={() => activationDialogHandler(row, false, null, true)}
				className={classes.incomingOnlyActivated}
			>
				Incoming Only
			</p>
		);
	  } else {
        return <Check className={classes.activated} />;
      }
  
}

const useStyles = makeStyles((theme: Theme) => ({
    switch: {
      borderRadius: theme.shape.borderRadius,
      border: "1px solid var(--color-dark-grey)",
      backgroundColor: theme.palette.common.white,
      width: "86px",
      height: "24px",
      padding: "2px 0",
      cursor: "pointer",
    },
    switchOn: {
      borderRight: "1px solid var(--color-dark-grey)",
      width: "50%",
      display: "block",
      fontSize: "14px"
    },
    pending: {
      color: theme.palette.common.pumpkinOrange,
    },
    activated: {
      color: theme.palette.success.main,
    },
    pendingLink: {
      color: theme.palette.common.pumpkinOrange,
      textDecoration: "underline",
      cursor: "pointer",
    },
	incomingOnlyActivated: {
		color: theme.palette.common.green,
		textDecoration: "underline",
      	cursor: "pointer",
	}
  }));

export default ActivationSwitch;