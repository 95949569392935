import React, { useEffect, useState } from 'react';
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import { Button, Typography, makeStyles, Theme } from '@material-ui/core';
import { IVirtualNumber } from '@models/TextMessaging.models';
import { formatPhoneNumber } from "@helpers/functions/phoneNumberFormatter";

interface SimpleActivationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (direction: string) => void;
  currentNumber: IVirtualNumber | null;
}

const SimpleActivationDialog: React.FC<SimpleActivationDialogProps> = ({
  open,
  onClose,
  onSubmit,
  currentNumber,
}) => {
  const classes = useStyles();
  const formattedNumber = currentNumber ? 
    formatPhoneNumber(currentNumber.phoneNumber.replace("+", "")) : "";
  const STEPS = {
	ACTIVATE: 'ACTIVATE',
	ACTIVATED: 'ACTIVATED',
  }
  const [currentStep, setCurrentStep] = useState<string>(STEPS.ACTIVATE);
  const dialogTitle = 'Text Messaging Activation';
  const headerText1 = `Activate Local Number ${formattedNumber}`;
  const messageText1 = 'Would you like to proceed with activating text messaging for this number?';
  const headerText2 = 'Text Messaging Activated';
  const messageText2 = 'Your text messaging activation request has been received. Please allow 1-3 business days for your number to become active across all networks';

  useEffect(() => {
    if (open) {
      setCurrentStep(STEPS.ACTIVATE);
    }
  }, [open]);

  const handleActivate = (confirmed: boolean) => {
    if (confirmed) {
	  onSubmit('Inbound and Outbound');
      setCurrentStep(STEPS.ACTIVATED);
    } else {
		onClose();
    }
  };

  const getCurrentContent = () => {
	switch (currentStep) {
	  case STEPS.ACTIVATE:
        return {
		  title: dialogTitle,
          header: headerText1,
          message: messageText1,
		  noButton: 'No',
		  yesButton: 'Yes',
          onConfirm: handleActivate,
        };

	  case STEPS.ACTIVATED:
        return {
		  title: dialogTitle,
          header: headerText2,
          message: messageText2,
		  noButton: 'Close',
          onConfirm: onClose,
        };

	  default:
		return null;
	}
  }

  const content = getCurrentContent();
  if (!content) return null;

  return (
    <Dialog 
      open={open} 
      title={content.title}
      onClose={onClose}
      className={classes.dialog}
	  maxWidth="sm"
      fullWidth
    >
      <DialogContent>
	    <Typography className={classes.header} variant="h5">
          {content.header}
        </Typography>
        <Typography>
          {content.message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
	    <Button 
          className={classes.cancelButton} 
          onClick={() => content.onConfirm(false)}
        >
          {content.noButton}
        </Button>
        {content.yesButton && (
			<DialogButton
			className={classes.agreeButton}
			color="secondary"
			onClick={() => content.onConfirm(true)}
			label={content.yesButton}
			/>
		)}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiDialogTitle-root h2": {
      fontSize: "1.5rem",
      fontWeight: 400
    }
  },
  header: {
    padding: theme.spacing(2, 0),
  },
  content: {
    padding: theme.spacing(1, 0),
  },
  dialogActions: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'stretch',
      padding: "8px 16px",
    }
  },
  cancelButton: {
    border: "1px solid var(--color-dark-grey)",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0 !important',
      marginBottom: theme.spacing(1)
    }
  },
  agreeButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0 !important'
    }
  }
}));

export default SimpleActivationDialog;